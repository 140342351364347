<template>
    <div>
        <CCard>
            <CCardHeader>
                <h3>Crear Banner</h3>
            </CCardHeader>
            <CCardBody>
                <ValidationObserver v-slot="{ handleSubmit }">
                    <form class="contact-form" method="post" v-on:submit.prevent="handleSubmit(registrar)">
                        <CRow>
                            <CCol sm="12">
                                <div class="form-group">
                                    <label>Posición:</label>
                                    <select class="form-control" v-model="registro.posicion">
                                        <option value="-1" selected="true">Seleccione un valor...</option>
                                        <option value="POSICION 1">POSICION 1</option>
                                        <option value="POSICION 2">POSICION 2</option>
                                        <option value="POSICION 3">POSICION 3</option>
                                        <option value="POSICION 4">POSICION 4</option>
                                        <option value="POSICION 5">POSICION 5</option>
                                    </select>
                                </div>
                            </CCol>
                        </CRow>

                        <CRow>
                            <CCol sm="12">
                                <div class="form-group">
                                    <label>Título:</label>
                                    <validation-provider name="Titulo" :rules="{ required: true }"
                                                         :customMessages="{ required: 'Es un campo requerido' }"
                                                         v-slot="validationContext">
                                        <input type="text" v-model="registro.titulo" class="form-control"/>
                                        <span style="color: red; font-size: small">
                      {{ validationContext.errors[0] }}
                    </span>
                                    </validation-provider>
                                </div>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol sm="12">
                                <div class="form-group">
                                    <label>Subtítulo:</label>
                                    <validation-provider name="Subtitulo" :rules="{ required: true }"
                                                         :customMessages="{ required: 'Es un campo requerido' }"
                                                         v-slot="validationContext">
                                        <input type="text" v-model="registro.subtitulo" class="form-control"/>
                                        <span style="color: red; font-size: small">
                      {{ validationContext.errors[0] }}
                    </span>
                                    </validation-provider>
                                </div>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol sm="12">
                                <div class="form-group">
                                    <label>Texto:</label>
                                    <validation-provider
                                            name="Texto" :rules="{ required: true }"
                                            :customMessages="{ required: 'Es un campo requerido' }"
                                            v-slot="validationContext">
                                        <input type="text" v-model="registro.texto" class="form-control"/>
                                        <span style="color: red; font-size: small">
                      {{ validationContext.errors[0] }}
                    </span>
                                    </validation-provider>
                                </div>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol sm="12">
                                <div class="form-group">
                                    <label>Tipo de Enlace:</label>
                                    <select class="form-control" v-model="registro.tipo_enlace">
                                        <option value="INTERNO">Interno</option>
                                        <option value="EXTERNO">Externo</option>
                                    </select>
                                </div>
                            </CCol>
                        </CRow>

                        <CRow>
                            <CCol sm="12">
                                <div class="form-group">
                                    <label>Texto Link:</label>
                                    <validation-provider
                                            name="Texto Link" :rules="{ required: true }"
                                            :customMessages="{ required: 'Es un campo requerido' }"
                                            v-slot="validationContext">
                                        <input type="text" v-model="registro.texto_link" class="form-control"/>
                                        <span style="color: red; font-size: small">
                      {{ validationContext.errors[0] }}
                    </span>
                                    </validation-provider>
                                </div>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol sm="12">
                                <div class="form-group">
                                    <label>URL Link:</label>
                                    <validation-provider
                                            name="URL Link" :rules="{ required: true }"
                                            :customMessages="{ required: 'Es un campo requerido' }"
                                            v-slot="validationContext">
                                        <input type="text" v-model="registro.url_link" class="form-control"/>
                                        <span style="color: red; font-size: small">
                      {{ validationContext.errors[0] }}
                    </span>
                                    </validation-provider>
                                </div>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol sm="12">
                                <div class="form-group row">
                                    <label class="col-md-4 form-control-label">Imagen:</label>
                                    <div class="col-md-12" v-if="!registro.imagen_subida">
                                        <img style="max-height: 100px;" :src="registro.imagen" alt=""/>
                                        <input name="inputFile" @change="handleImage" class="form-control" type="file"
                                               accept="image/png, image/jpeg, image/jpg"/>
                                    </div>
                                    <div class="col-md-9" v-if="registro.imagen_subida">
                                        <img style="max-height: 100px; width: 100px"
                                             :src="'/storage/eco/small_' + registro.imagen" alt=""/>&nbsp;
                                        <button type="button" class="btn btn-sm btn-danger"
                                                @click="borrarImagen(registro.id, registro.imagen)">
                                            <i class="cil-trash"></i>
                                            Borrar
                                        </button>
                                    </div>
                                </div>
                            </CCol>
                        </CRow>


                        <div class="form-group">
                            <button type="submit" class="btn btn-primary">
                                <span class="cil-save btn-icon mr-2"></span>
                                Guardar
                            </button>

                            <router-link to="/ecoEtiquetas/List">
                                <CButton class="ml-2" color="secondary">
                                    <span class="cil-x-circle btn-icon mr-2"></span>
                                    Cancelar
                                </CButton>
                            </router-link>
                        </div>
                    </form>
                </ValidationObserver>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import httpService from "@/services/httpService"

export default {
    components: {},
    data() {
        return {
            urlImage: process.env.VUE_APP_STORAGE,
            registro: {},
        }
    },

    methods: {
        async registrar() {
            try {
                let url = "eco-banners";
                let response = await httpService.post(url, this.registro);
                this.$vToastify.success("Operación realizada con éxito", "Éxito");
                this.$router.back();
            } catch (error) {
                console.log(error);
            }
        },

        handleImage(e) {
            const selectedImage = e.target.files[0]; // get first file
            if (selectedImage.type === 'image/jpeg' || selectedImage.type === 'image/jpg' || selectedImage.type === 'image/png') {
                this.createBase64Image(selectedImage);
            } else {
                this.registro.imagen = 'invalid';
                Vue.$toast.success('Tipo de imagen no válida. Por favor, seleccione otro archivo.', {
                    type: 'error',
                    position: 'top-right'
                });
            }
        },

        createBase64Image(fileObject) {
            const reader = new FileReader();
            reader.onload = (e) => {
                this.registro.imagen = e.target.result;
            };
            reader.readAsDataURL(fileObject);
        },
    }
}
</script>